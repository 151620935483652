import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"

const Contact = () => (
  <Layout>
    <Seo title="Contact" />
    <section>Hello contact</section>
  </Layout>
)

export default Contact
